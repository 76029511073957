/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .dark {
    --color-text-muted: #fbbe5f;
    --color-text-main: 238, 239, 241;
    --color-main: 28, 28, 28;
    --color-muted: 51, 51, 51;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #333 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input {
  caret-color: black;
  border-color: #fbbe5f !important;
  background-color: #333333 !important;
}
input:focus {
  border-color: #fbbe5f;
}

.bg-gray {
  background-color: #333333 !important;
  background: #333333 !important;
}

html,
body,
#root,
#root > div {
  width: 100vw;
  font-family: "Helvetica", "Arial", sans-serif;
  background-color: "text-skin-main";
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #333333;
}

h2 {
  font-family: "Audiowide", "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background-color: #fbbe5f;
}

.window {
  position: relative;
  display: block;
  width: 360px;
  height: 567px;
 
  border-radius: 3px;
  background: #f1f1f1;
}
.window .header {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;

  overflow: hidden;
  transition: all 0.5s ease-out, background 1s ease-out;
  transition-delay: 0.2s;
  z-index: 1;
}
.window .header .burger-container {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 100%;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.window .header .burger-container #burger {
  width: 18px;
  height: 8px;
  position: relative;
  display: block;
  margin: -4px auto 0;
  top: 50%;
}
.window .header .burger-container #burger .bar {
  width: 100%;
  height: 1px;
  display: block;
  position: relative;
  background: #fff;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0s;
}
.window .header .burger-container #burger .bar.topBar {
  transform: translateY(0px) rotate(0deg);
}
.window .header .burger-container #burger .bar.btmBar {
  transform: translateY(6px) rotate(0deg);
}
.window .header .icon {
  display: inline-block;
  position: absolute;
  height: 100%;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  left: 50%;
  transform: translateX(-50%);
}

.window .header ul.menu {
  position: relative;
  display: block;
  padding: 0px 48px 0;
  list-style: none;
}
.window .header ul.menu li.menu-item {
    background-color: #333333 !important;
  background: #333333 !important;
  border-bottom: 1px solid #333;
  margin-top: 5px;
  transform: scale(1.15) translateY(-30px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
    opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}

.window .header ul.menu li.menu-item a {
  color: #fff;
}
.window .header.menu-opened {
  height: 100%;
  z-index: 2;
  transition: all 0.3s ease-in, background 0.5s ease-in;
  transition-delay: 0.25s;
}
.window .header.menu-opened .burger-container {
  transform: rotate(270deg);
}
.window .header.menu-opened .burger-container #burger .bar {
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.2s;
}
.window .header.menu-opened .burger-container #burger .bar.topBar {
  transform: translateY(4px) rotate(45deg);
}
.window .header.menu-opened .burger-container #burger .bar.btmBar {
  transform: translateY(3px) rotate(-45deg);
}
.window .header.menu-opened ul.menu li.menu-item {
  transform: scale(1) translateY(0px);
  opacity: 1;
}
.window .header.menu-opened ul.menu li.menu-item:nth-child(1) {
  transition-delay: 0.27s;
}
.window .header.menu-opened ul.menu li.menu-item:nth-child(2) {
  transition-delay: 0.34s;
}
.window .header.menu-opened ul.menu li.menu-item:nth-child(3) {
  transition-delay: 0.41s;
}
.window .header.menu-opened ul.menu li.menu-item:nth-child(4) {
  transition-delay: 0.48s;
}
.window .header.menu-opened ul.menu li.menu-item:nth-child(5) {
  transition-delay: 0.55s;
}
.window .header.menu-opened ul.menu li.menu-item:nth-child(6) {
  transition-delay: 0.62s;
}
.window .header.menu-opened ul.menu li.menu-item:nth-child(7) {
  transition-delay: 0.69s;
}

.window .header.menu-opened .icon.icon-bag {
  transform: translateX(75px);
  transition-delay: 0.3s;
}
.window .content {
  font-family: "Audiowide", "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif;
  padding: 67px 4% 0;
  text-align: justify;
  overflow: scroll;
  max-height: 100%;
}
.window .content::-webkit-scrollbar {
  display: none;
}
.window .content h2 {
  margin-bottom: 0px;
  letter-spacing: 1px;
}
.window .content img {
  width: 95%;
  position: relative;
  display: block;
  margin: 75px auto 75px;
}
.window .content img:nth-of-type(2) {
  margin: 75px auto;
}
@media (max-width: 600px) {
  .window {
    width: 100%;
    height: 100vh;
    margin: 0;
    border-radius: 0px;
  }
  /* .window .header {
    position: fixed;
  } */
}
.window .header .clear {
  z-index: 0;
}
.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
 
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  outline: none !important;
  font-family: "Audiowide", "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #fbbe5f,
   
    #fbbe5f,
   
    #fbbe5f,
    
    #fbbe5f
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;

  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
